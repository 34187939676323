import React, { createContext, useContext, useState, ReactNode } from 'react';
import { SearchParty, AccountAppsWithPermissionsProps } from '../pages/UserManagement/userManagementUtils/types';
import { UpdateAccountApps, UpdateLinkedParties, GetUsersBody, UserTypeFromInternal } from '../pages/UserManagement/userManagementUtils/types';

type SearchContextType = {
    searchTerm: string | null;
    pressedEnter: boolean;
    searchIsLoading: boolean;
    searchPartiesResult: SearchParty[];
    accountAppsWithPermissions: AccountAppsWithPermissionsProps;
    selectedParty: SearchParty | null;
    searchError: boolean;
    initialApps: UpdateAccountApps | null;
    accountAppsToUpdate: UpdateAccountApps | null;
    initialLinkedParties: UpdateLinkedParties;
    linkedPartiesToUpdate: UpdateLinkedParties;
    getUsersBody: GetUsersBody;
    usersListFromInternal: UserTypeFromInternal[] | null;
    setSearchTerm: React.Dispatch<React.SetStateAction<string | null>>;
    setPressedEnter: React.Dispatch<React.SetStateAction<boolean>>;
    setSearchIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setSearchPartiesResult: React.Dispatch<React.SetStateAction<SearchParty[]>>;
    setAccountAppsWithPermissions: React.Dispatch<React.SetStateAction<AccountAppsWithPermissionsProps>>;
    setSelectedParty: React.Dispatch<React.SetStateAction<SearchParty | null>>;
    setSearchError: React.Dispatch<React.SetStateAction<boolean>>;
    setInitialApps: React.Dispatch<React.SetStateAction<UpdateAccountApps>>;
    setAccountAppsToUpdate: React.Dispatch<React.SetStateAction<UpdateAccountApps>>;
    setInitialLinkedParties: React.Dispatch<React.SetStateAction<UpdateLinkedParties>>;
    setLinkedPartiesToUpdate: React.Dispatch<React.SetStateAction<UpdateLinkedParties>>;
    setGetUsersBody: React.Dispatch<React.SetStateAction<GetUsersBody>>;
    setUsersListFromInternal: React.Dispatch<React.SetStateAction<UserTypeFromInternal[] | null>>;
}

const SearchContext = createContext<SearchContextType | undefined>(undefined);

/* Manage account (internal user) */
const INITIAL_ACCOUNT_APPS_WITH_PERMISSIONS = {
    accounts: [],
    isLoading: false,
    error: false,
    noAccount: false
};
const INITIAL_ACCOUNT_APPS_TO_UPDATE = {
    accountId: '',
    userId: '',
    apps: []
}
const INITIAL_LINKED_PARTIES = {
    accountId: '',
    parties: []
}

/* Manage users (internal user) */
const INITIAL_GET_USERS_BODY = {
    "search": "",
    "fieldToSearch": "all",
    "includeSuperusers": false,
    "includeInactive": false
}

export function SearchProvider({ children }: { children: ReactNode }) {
    const [searchTerm, setSearchTerm] = useState<string | null>(null);
    const [pressedEnter, setPressedEnter] = useState(false);
    const [searchIsLoading, setSearchIsLoading] = useState<boolean>(false);
    /* Manage account (internal user) */
    const [searchPartiesResult, setSearchPartiesResult] = useState<SearchParty[]>([]);
    const [searchError, setSearchError] = useState(false);
    const [accountAppsWithPermissions, setAccountAppsWithPermissions] = useState<AccountAppsWithPermissionsProps>(INITIAL_ACCOUNT_APPS_WITH_PERMISSIONS);
    const [selectedParty, setSelectedParty] = useState<SearchParty | null>(null);
    const [initialApps, setInitialApps] = useState<UpdateAccountApps>(INITIAL_ACCOUNT_APPS_TO_UPDATE);
    const [accountAppsToUpdate, setAccountAppsToUpdate] = useState<UpdateAccountApps>(INITIAL_ACCOUNT_APPS_TO_UPDATE);
    const [initialLinkedParties, setInitialLinkedParties] = useState<UpdateLinkedParties>(INITIAL_LINKED_PARTIES);
    const [linkedPartiesToUpdate, setLinkedPartiesToUpdate] = useState<UpdateLinkedParties>(INITIAL_LINKED_PARTIES);
    /* Manage users (internal user) */
    const [getUsersBody, setGetUsersBody] = useState<GetUsersBody>(INITIAL_GET_USERS_BODY);
    const [usersListFromInternal, setUsersListFromInternal] = useState<UserTypeFromInternal[] | null>(null);

    return (
        <SearchContext.Provider value={{
            searchTerm,
            pressedEnter,
            searchIsLoading,
            searchPartiesResult,
            initialApps,
            accountAppsWithPermissions,
            selectedParty,
            searchError,
            accountAppsToUpdate,
            initialLinkedParties,
            linkedPartiesToUpdate,
            getUsersBody,
            usersListFromInternal,
            setSearchTerm,
            setPressedEnter,
            setSearchIsLoading,
            setSearchPartiesResult,
            setAccountAppsWithPermissions,
            setSelectedParty,
            setSearchError,
            setInitialApps,
            setAccountAppsToUpdate,
            setInitialLinkedParties,
            setLinkedPartiesToUpdate,
            setGetUsersBody,
            setUsersListFromInternal
        }}>
            {children}
        </SearchContext.Provider>
    );
}

export function useSearch() {
    const context = useContext(SearchContext);
    if (!context) {
        throw new Error('useSearch must be used within a SearchProvider');
    }
    return context;
}
